// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as H2 from "../../../../../../styleguide/components/Heading/H2.res.js";
import * as HorizontalScroll from "../../../../../../styleguide/components/HorizontalScroll/HorizontalScroll.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ZenlayerPricingScss from "./ZenlayerPricing.scss";

var css = ZenlayerPricingScss;

function ZenlayerPricing(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(H2.make, {
                      className: css.title,
                      children: "Zenlayer Bare Metal Pricing and Configurations "
                    }),
                JsxRuntime.jsxs(HorizontalScroll.make, {
                      children: [
                        JsxRuntime.jsx("div", {
                              className: css.spacer
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: "Plan",
                                      className: css.cell
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: "CPUs",
                                      className: css.cell
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: "Memory",
                                      className: css.cell
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: "Hard disks",
                                      className: css.cell
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: "RAID",
                                      className: css.cell
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: "Internet",
                                      className: css.cell
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: "Cloud Networking",
                                      className: css.cell
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: "Pricing",
                                      className: css.cell
                                    })
                              ],
                              className: Cx.cx([
                                    css.column,
                                    css.meta
                                  ])
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: "Small",
                                      className: Cx.cx([
                                            css.cell,
                                            css.heading
                                          ])
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: "CPUs",
                                              className: css.mobileOnlyMeta
                                            }),
                                        "1 x Intel® Xeon® CPU E3-1230 v6 @ 3.40-3.50 GHz (4 cores)"
                                      ],
                                      className: css.cell
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: "Memory",
                                              className: css.mobileOnlyMeta
                                            }),
                                        "16GB to 32GB RAM"
                                      ],
                                      className: css.cell
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: "Hard disk",
                                              className: css.mobileOnlyMeta
                                            }),
                                        "1 x 2 TB SATA or 480G SSD"
                                      ],
                                      className: css.cell
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: "RAID",
                                              className: css.mobileOnlyMeta
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: "-",
                                              className: css.mobileOnlyMeta
                                            })
                                      ],
                                      className: css.cell
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: "Internet",
                                              className: css.mobileOnlyMeta
                                            }),
                                        "Up to 10 Gbps uplink"
                                      ],
                                      className: css.cell
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: "Cloud Networking",
                                              className: css.mobileOnlyMeta
                                            }),
                                        "Up to 1 Gbps LAN connection"
                                      ],
                                      className: css.cell
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsxs("div", {
                                            children: [
                                              "from",
                                              JsxRuntime.jsx("span", {
                                                    children: "$139"
                                                  }),
                                              "/month"
                                            ]
                                          }),
                                      className: Cx.cx([
                                            css.cell,
                                            css.price
                                          ])
                                    })
                              ],
                              className: Cx.cx([
                                    css.column,
                                    css.small
                                  ])
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: "Medium",
                                      className: Cx.cx([
                                            css.cell,
                                            css.heading
                                          ])
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: "CPUs",
                                              className: css.mobileOnlyMeta
                                            }),
                                        "Dual Intel® Xeon® Silver 4216 CPU @ 16 core 2.10GHz"
                                      ],
                                      className: css.cell
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: "Memory",
                                              className: css.mobileOnlyMeta
                                            }),
                                        "64GB to 384GB RAM"
                                      ],
                                      className: css.cell
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: "Hard disk",
                                              className: css.mobileOnlyMeta
                                            }),
                                        "4-6 x 480G SSD"
                                      ],
                                      className: css.cell
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: "RAID",
                                              className: css.mobileOnlyMeta
                                            }),
                                        "Hardware RAID (0, 1, 5, 10)"
                                      ],
                                      className: css.cell
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: "Internet",
                                              className: css.mobileOnlyMeta
                                            }),
                                        "Up to 20 Gbps uplink"
                                      ],
                                      className: css.cell
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: "Cloud Networking",
                                              className: css.mobileOnlyMeta
                                            }),
                                        "Up to 20 Gbps LAN connection"
                                      ],
                                      className: css.cell
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsxs("div", {
                                            children: [
                                              "from",
                                              JsxRuntime.jsx("span", {
                                                    children: "$479"
                                                  }),
                                              "/month"
                                            ]
                                          }),
                                      className: Cx.cx([
                                            css.cell,
                                            css.price
                                          ])
                                    })
                              ],
                              className: Cx.cx([
                                    css.column,
                                    css.medium
                                  ])
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: "Large",
                                      className: Cx.cx([
                                            css.cell,
                                            css.heading
                                          ])
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: "CPUs",
                                              className: css.mobileOnlyMeta
                                            }),
                                        "Dual Intel® Xeon® Gold 6226R CPU @ 16 core 2.90GHz"
                                      ],
                                      className: css.cell
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: "Memory",
                                              className: css.mobileOnlyMeta
                                            }),
                                        "128GB to 384GB RAM"
                                      ],
                                      className: css.cell
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: "Hard disk",
                                              className: css.mobileOnlyMeta
                                            }),
                                        "14 trays, up to 72 TB SAS/SATA"
                                      ],
                                      className: css.cell
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: "RAID",
                                              className: css.mobileOnlyMeta
                                            }),
                                        "Hardware RAID (0, 1, 5, 10)"
                                      ],
                                      className: css.cell
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: "Internet",
                                              className: css.mobileOnlyMeta
                                            }),
                                        "Up to 20 Gbps uplink"
                                      ],
                                      className: css.cell
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: "Cloud Networking",
                                              className: css.mobileOnlyMeta
                                            }),
                                        "Up to 20 Gbps LAN connection"
                                      ],
                                      className: css.cell
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsxs("div", {
                                            children: [
                                              "from",
                                              JsxRuntime.jsx("span", {
                                                    children: "$889"
                                                  }),
                                              "/month"
                                            ]
                                          }),
                                      className: Cx.cx([
                                            css.cell,
                                            css.price
                                          ])
                                    })
                              ],
                              className: Cx.cx([
                                    css.column,
                                    css.large
                                  ])
                            }),
                        JsxRuntime.jsx("div", {
                              className: css.spacer
                            })
                      ],
                      className: css.horizontalScrollRow
                    })
              ],
              className: css.wrapper
            });
}

var make = ZenlayerPricing;

export {
  css ,
  make ,
}
/* css Not a pure module */
