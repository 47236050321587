// Generated by ReScript, PLEASE EDIT WITH CARE

import * as BannerSignUpToday from "../../../../common/banner-sign-up/BannerSignUpToday.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function BannerSignUpTodayZenlayerBareMetal(props) {
  return JsxRuntime.jsx(BannerSignUpToday.make, {
              title: "Get VIP Access When You Sign up Today!",
              bannerType: "ZenlayerBareMetal",
              deployNowButtonComponent: undefined
            });
}

var make = BannerSignUpTodayZenlayerBareMetal;

export {
  make ,
}
/* BannerSignUpToday Not a pure module */
