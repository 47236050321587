// Generated by ReScript, PLEASE EDIT WITH CARE

import * as BannerReasons from "../../../../../../styleguide/components/BannerReasons/BannerReasons.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as BannerSingleReason from "../../../../../../styleguide/components/BannerReasons/BannerSingleReason.res.js";
import IconMultiRegionSvg from "../../../common/assets/icon-multi-region.svg";
import IconTechSupportSvg from "../../../common/assets/icon-tech-support.svg";
import IconScaleUpNDownSvg from "../../../common/assets/icon-scale-up-n-down.svg";
import IconFlexibleBillingSvg from "../../../common/assets/icon-flexible-billing.svg";

var iconScaleUpNDown = IconScaleUpNDownSvg;

var iconTechSupport = IconTechSupportSvg;

var iconMultiRegion = IconMultiRegionSvg;

var iconFlexibleBilling = IconFlexibleBillingSvg;

function CompleteControlWithZenlayer(props) {
  return JsxRuntime.jsxs(BannerReasons.make, {
              title: "Servers Dedicated Exclusively to Your Business",
              subtitle: "Zenlayer Bare Metal Features",
              children: [
                JsxRuntime.jsx(BannerSingleReason.make, {
                      title: "Scale Up and Down",
                      description: "Use the bare metal console to add or remove servers at any time, to any region effortlessly.",
                      svgIcon: iconScaleUpNDown
                    }),
                JsxRuntime.jsx(BannerSingleReason.make, {
                      title: "24/7 Tech Support",
                      description: "Free, live technical support with <15 min response time 24/7. 95% of tickets resolved in less than 4 hours.",
                      svgIcon: iconTechSupport
                    }),
                JsxRuntime.jsx(BannerSingleReason.make, {
                      title: "Deploy to 25 Countries",
                      description: "Access emerging markets like China, India, Brazil, Indonesia, Western Africa, South Africa, and more.",
                      svgIcon: iconMultiRegion
                    }),
                JsxRuntime.jsx(BannerSingleReason.make, {
                      title: "Flexible Billing",
                      description: "Upfront, transparent pricing allowing you to choose either hourly or monthly billing.",
                      svgIcon: iconFlexibleBilling
                    })
              ]
            });
}

var make = CompleteControlWithZenlayer;

export {
  iconScaleUpNDown ,
  iconTechSupport ,
  iconMultiRegion ,
  iconFlexibleBilling ,
  make ,
}
/* iconScaleUpNDown Not a pure module */
