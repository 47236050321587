// Generated by ReScript, PLEASE EDIT WITH CARE

import * as SubNavigation from "../../../../../../styleguide/components/SubNavigation/SubNavigation.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function SubNavigationZenlayerBareMetal(props) {
  return JsxRuntime.jsxs(SubNavigation.make, {
              title: "Bare Metal",
              children: [
                JsxRuntime.jsx(SubNavigation.Link.make, {
                      href: "#bare_metal_pricing",
                      text: "Pricing"
                    }),
                JsxRuntime.jsx(SubNavigation.Link.make, {
                      href: "#bare_metal_why",
                      text: "Why Zenlayer?"
                    }),
                JsxRuntime.jsx(SubNavigation.Link.make, {
                      href: "#bare_metal_locations",
                      text: "Locations"
                    }),
                JsxRuntime.jsx(SubNavigation.Link.make, {
                      href: "#bare_metal_case_study",
                      text: "Case Study"
                    })
              ]
            });
}

var make = SubNavigationZenlayerBareMetal;

export {
  make ,
}
/* SubNavigation Not a pure module */
