// Generated by ReScript, PLEASE EDIT WITH CARE

import * as BannerReasons from "../../../../../../styleguide/components/BannerReasons/BannerReasons.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as WhyZenlayerScss from "./WhyZenlayer.scss";
import * as BannerSingleReason from "../../../../../../styleguide/components/BannerReasons/BannerSingleReason.res.js";
import IconDedicatedserversSvg from "../../../common/assets/icon-dedicatedservers.svg";
import IconRapidDeploymentSvg from "../../../common/assets/icon-rapid-deployment.svg";
import IconConnectedToCloudSvg from "../../../common/assets/icon-connected-to-cloud.svg";
import IconNotJustBaremetalSvg from "../../../common/assets/icon-not-just-baremetal.svg";

var css = WhyZenlayerScss;

var iconConnectedToCloud = IconConnectedToCloudSvg;

var iconNotJustBaremetal = IconNotJustBaremetalSvg;

var iconDedicatedServers = IconDedicatedserversSvg;

var iconRapidDeployment = IconRapidDeploymentSvg;

function WhyZenlayer(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs(BannerReasons.make, {
                    title: "Why Zenlayer Bare Metal Cloud?",
                    description: "Our bare metal servers offer flexible pricing, global on-demand availability, and easily scale up or down.",
                    children: [
                      JsxRuntime.jsx(BannerSingleReason.make, {
                            title: "Connected to the Cloud",
                            description: "Our bare metal servers are managed in one simple platform and can be connected to multiple public clouds.",
                            svgIcon: iconConnectedToCloud
                          }),
                      JsxRuntime.jsx(BannerSingleReason.make, {
                            title: "Not Just Bare Metal",
                            description: "Our bare metal platform offers more including CDN, Cloud Networking, Edge Data Center Services and IP Transit.",
                            svgIcon: iconNotJustBaremetal
                          }),
                      JsxRuntime.jsx(BannerSingleReason.make, {
                            title: "Dedicated Servers",
                            description: "These servers are dedicated just to your business, which is fully customizable and offers better performance.",
                            svgIcon: iconDedicatedServers
                          }),
                      JsxRuntime.jsx(BannerSingleReason.make, {
                            title: "Rapid Deployment",
                            description: "Quickly build a global network of servers that reach your users anywhere.",
                            svgIcon: iconRapidDeployment
                          })
                    ]
                  }),
              className: css.root
            });
}

var make = WhyZenlayer;

export {
  css ,
  iconConnectedToCloud ,
  iconNotJustBaremetal ,
  iconDedicatedServers ,
  iconRapidDeployment ,
  make ,
}
/* css Not a pure module */
