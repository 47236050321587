// Generated by ReScript, PLEASE EDIT WITH CARE

import * as User from "../../../../models/User.res.js";
import * as WhyZenlayer from "./components/why-zenlayer/WhyZenlayer.res.js";
import * as ZenlayerPricing from "./components/pricing/ZenlayerPricing.res.js";
import * as CaseStudyZenlayer from "./components/case-study/CaseStudyZenlayer.res.js";
import * as JumbotronZenlayer from "./components/jumbotron/JumbotronZenlayer.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ServicesSeparatorBlock from "../../ServicesSeparatorBlock.res.js";
import * as ZenlayerBareMetalScss from "./ZenlayerBareMetal.scss";
import * as CompleteControlWithZenlayer from "./components/complete-control/CompleteControlWithZenlayer.res.js";
import * as SubNavigationZenlayerBareMetal from "./components/sub-navigation/SubNavigationZenlayerBareMetal.res.js";
import * as RequestEstimateZenlayerBareMetal from "./components/request-estimate/RequestEstimateZenlayerBareMetal.res.js";
import ZenlayerHeaderLeftSvg from "./assets/zenlayer-header-left.svg";
import ZenlayerHeaderRightSvg from "./assets/zenlayer-header-right.svg";
import * as BannerSignUpTodayZenlayerBareMetal from "./components/sign-up-today/BannerSignUpTodayZenlayerBareMetal.res.js";
import HeaderDotsSvg from "../../common/assets/header-dots.svg";
import * as ComponentsImportsLoadableJsx from "Loadable/components.imports-loadable.jsx";

var css = ZenlayerBareMetalScss;

var headerDotsSvg = HeaderDotsSvg;

var bareMetalHeaderLeftSvg = ZenlayerHeaderLeftSvg;

var bareMetalHeaderRightSvg = ZenlayerHeaderRightSvg;

function ZenlayerBareMetal$default(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx(JumbotronZenlayer.make, {}),
                            className: css.backgroundImage,
                            style: {
                              backgroundImage: "url(" + bareMetalHeaderLeftSvg + "), url(" + bareMetalHeaderRightSvg + ")"
                            }
                          }),
                      className: css.jumbotronSection
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx(SubNavigationZenlayerBareMetal.make, {}),
                            className: "container"
                          }),
                      className: css.subNavigationBareMetalSection
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx("div", {
                                    className: css.anchor,
                                    id: "bare_metal_pricing"
                                  }),
                              JsxRuntime.jsx(ZenlayerPricing.make, {})
                            ],
                            className: css.pricingAndConfigurationsSectionContainer
                          }),
                      className: css.pricingAndConfigurationsSection
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(RequestEstimateZenlayerBareMetal.make, {}),
                      className: css.requestEstimateBareMetalSection
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(CompleteControlWithZenlayer.make, {}),
                      className: css.completeControlWithBareMetalSection
                    }),
                JsxRuntime.jsx("div", {
                      className: css.anchor,
                      id: "bare_metal_why"
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(WhyZenlayer.make, {}),
                      className: css.whyBareMetalSection
                    }),
                JsxRuntime.jsx(ServicesSeparatorBlock.make, {}),
                JsxRuntime.jsx("div", {
                      className: css.anchor,
                      id: "bare_metal_locations"
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(ComponentsImportsLoadableJsx.ZenlayerBareMetalCloudMap, {}),
                      className: css.map
                    }),
                JsxRuntime.jsx("div", {
                      className: css.anchor,
                      id: "bare_metal_case_study"
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(CaseStudyZenlayer.make, {
                            zenlayerCaseStudy: props.zenlayerCaseStudy,
                            userLoginStatus: User.LoginStatus.fromBool(props.isUserLoggedIn),
                            setUserData: props.setUserData
                          })
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx(BannerSignUpTodayZenlayerBareMetal.make, {}),
                            style: {
                              backgroundImage: "url(" + headerDotsSvg + ")",
                              backgroundSize: "cover"
                            }
                          }),
                      className: css.signUpTodaySection
                    })
              ],
              className: css.wrapper
            });
}

var $$default = ZenlayerBareMetal$default;

export {
  css ,
  headerDotsSvg ,
  bareMetalHeaderLeftSvg ,
  bareMetalHeaderRightSvg ,
  $$default as default,
}
/* css Not a pure module */
