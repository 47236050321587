// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../../../../libs/ID.res.js";
import * as Url from "../../../../../../utils/Url.res.js";
import * as React from "react";
import * as Button from "../../../../../../styleguide/components/Button/Button.res.js";
import * as $$Promise from "../../../../../../bindings/Promise.res.js";
import * as Cloudinary from "../../../../../../libs/Cloudinary.res.js";
import * as SentryLogger from "../../../../../../loggers/SentryLogger.res.js";
import * as BannerCaseStudy from "../../../../common/banner-case-study/BannerCaseStudy.res.js";
import * as Routes_Resource from "../../../../../../routes/common/Routes_Resource.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as SignInModalContainer from "../../../../../../styleguide/modals/SignInModal/SignInModalContainer.res.js";
import * as NotifyBareMetalCaseStudyDownloadRequest from "../../../../../../api/requests/NotifyBareMetalCaseStudyDownloadRequest.res.js";

var zenlayer = "zenlayer";

var initialState = {
  signInModal: "Hidden"
};

function reducer(_state, action) {
  return {
          signInModal: action._0
        };
}

function CaseStudyZenlayer(props) {
  var userLoginStatus = props.userLoginStatus;
  var zenlayerCaseStudy = props.zenlayerCaseStudy;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var match$1 = match[0].signInModal;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(BannerCaseStudy.make, {
                      title: "Zenlayer Bare Metal Case Study",
                      description: "Gaming platform rapidly builds global network using Bare Metal Cloud. Bare Metal Cloud quickly and easily provisions servers around the world to meet user needs.",
                      ctaComponent: zenlayerCaseStudy !== undefined ? JsxRuntime.jsx(Button.make, {
                              size: "MD",
                              color: "Teal",
                              onClick: (function (param) {
                                  if (userLoginStatus === "LoggedIn") {
                                    return $$Promise.wait(NotifyBareMetalCaseStudyDownloadRequest.exec(ID.toString(zenlayerCaseStudy.id), zenlayer), (function (x) {
                                                  if (x.TAG !== "Ok") {
                                                    return SentryLogger.error1({
                                                                rootModule: "CaseStudyZenlayer",
                                                                subModulePath: /* [] */0,
                                                                value: "make",
                                                                fullPath: "CaseStudyZenlayer.make"
                                                              }, "CaseStudyZenlayer" + "::CaseStudyZenlayer::notifyDownload::Error", [
                                                                "Error",
                                                                x._0
                                                              ]);
                                                  }
                                                  Url.visitBlank(zenlayerCaseStudy.privateDownload);
                                                  Url.visit(Routes_Resource.downloadThanks(zenlayerCaseStudy.title));
                                                }));
                                  } else {
                                    return dispatch({
                                                TAG: "ToggleSignInModal",
                                                _0: "Shown"
                                              });
                                  }
                                }),
                              children: "Download Case Study"
                            }) : null,
                      testimonialText: "\"Over 6 months, more than 1,100 bare metal servers were deployed across 5 continents, including sizable presences in emerging markets like Brazil and the Philippines.\"",
                      testimonialAuthorName: "Lawrence \"LL\" Lee",
                      testimonialAuthorRole: "VP of Partnerships & Alliances at Zenlayer",
                      testimonialAuthorImage: Cloudinary.imageUrl("v1654530936/static/lawrence_lee_zenlayer")
                    }),
                match$1 === "Shown" ? JsxRuntime.jsx(SignInModalContainer.make, {
                        close: (function () {
                            dispatch({
                                  TAG: "ToggleSignInModal",
                                  _0: "Hidden"
                                });
                          }),
                        callback: (function () {
                            
                          }),
                        setUserData: props.setUserData,
                        subTitle: "Sign In to download the case study"
                      }) : null
              ]
            });
}

var make = CaseStudyZenlayer;

export {
  zenlayer ,
  initialState ,
  reducer ,
  make ,
}
/* ID Not a pure module */
