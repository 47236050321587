// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Html from "../../../../../../libs/Html.res.js";
import * as React from "react";
import * as UnderscoreFancy from "../../../../../../styleguide/components/UnderscoreFancy/UnderscoreFancy.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as BannerRequestEstimate from "../../../../common/banner-request-estimate/BannerRequestEstimate.res.js";
import * as ScheduleModalServices from "../../../../common/schedule-modal-services/ScheduleModalServices.res.js";

function RequestEstimateZenlayerBareMetal(props) {
  var match = React.useState(function () {
        return "Closed";
      });
  var setScheduleDemoState = match[1];
  var tmp;
  tmp = match[0] === "Opened" ? JsxRuntime.jsx(ScheduleModalServices.make, {
          onClose: (function () {
              setScheduleDemoState(function (param) {
                    return "Closed";
                  });
            }),
          title: "Request Zenlayer Estimate",
          subtitle: "Submit the form to get a free estimate and learn more about Zenlayer bare metal solutions.",
          buttonTitle: "Submit Request",
          leadType: "ZenlayerBareMetal"
        }) : null;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(BannerRequestEstimate.make, {
                      title: "Our experts can calculate the estimated cost for your Zenlayer bare metal deployment.",
                      buttonTitle: "Request Estimate",
                      buttonSubtitleComponent: JsxRuntime.jsxs(JsxRuntime.Fragment, {
                            children: [
                              "Get",
                              JsxRuntime.jsx(UnderscoreFancy.make, {
                                    children: Html.nbsp + "VIP Access!",
                                    color: "Teal"
                                  })
                            ]
                          }),
                      onButtonClick: (function (param) {
                          setScheduleDemoState(function (param) {
                                return "Opened";
                              });
                        }),
                      color: "Primary"
                    }),
                tmp
              ]
            });
}

var make = RequestEstimateZenlayerBareMetal;

export {
  make ,
}
/* react Not a pure module */
